import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SupportScriptsService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  private addClarity(renderer2: Renderer2, document: Document): void {
    let script = renderer2.createElement('script');
    script.text = `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "mtylobpqqw");
    `;
    script.type = 'text/javascript';
    renderer2.appendChild(document.head, script);
  }

  private addGoogleTagManager(renderer2: Renderer2, document: Document): void {
    let script = renderer2.createElement('script');
    script.text = `
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          "gtm.start": new Date().getTime(),
          event: "gtm.js",
        });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-5DD9SB8");
    `;
    script.type = 'text/javascript';
    renderer2.appendChild(document.head, script);
  }

  private addRDStation(renderer2: Renderer2, document: Document): void {
    let script = renderer2.createElement('script');
    script.text = `
      window.setTimeout(function () {
        (function () {
          var script = document.createElement("script");
          script.src =
            "https://d335luupugsy2.cloudfront.net/js/loader-scripts/bd622b99-95b9-442c-a659-c3f9cf740312-loader.js";
          script.async = true;
          document.body.appendChild(script);
        })();
      });
    `;
    script.type = 'text/javascript';
    renderer2.appendChild(document.body, script);
  }

  private addSecuritiAI(renderer2: Renderer2, document: Document): void {
    let script = renderer2.createElement('script');
    script.text = `
      (function () {
        var s = document.createElement("script");
        s.src =
          "https://cdn-prod.securiti.ai/consent/cookie-consent-sdk-loader.js";
        s.setAttribute(
          "data-tenant-uuid",
          "da0bdc52-8ccb-405a-a420-0a5a9ef4467e"
        );
        s.setAttribute(
          "data-domain-uuid",
          "72ee5f32-627d-4c37-944e-4eca25027aaf"
        );
        s.setAttribute("data-backend-url", "https://app.securiti.ai");
        s.setAttribute("data-skip-css", "false");
        s.defer = true;
        var parent_node = document.head || document.body;
        parent_node.appendChild(s);
      })();
    `;
    script.type = 'text/javascript';
    renderer2.appendChild(document.body, script);
  }

  private addSiteBlindado(renderer2: Renderer2, document: Document): void {
    let script = renderer2.createElement('script');
    script.src = 'https://cdn.siteblindado.com/aw.js';
    script.async = true;
    renderer2.appendChild(document.body, script);
  }

  public injectScripts(renderer2: Renderer2, document: Document): void {
    this.addGoogleTagManager(renderer2, document);
    // this.addSiteBlindado(renderer2, document);

    if (isPlatformBrowser(this.platformId)) {
      this.addClarity(renderer2, document);
      this.addRDStation(renderer2, document);
      this.addSecuritiAI(renderer2, document);
    }
  }
}
