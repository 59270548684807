<div class="dialog-backdrop" [ngClass]="{ hidden: !show }">
  <button
    class="dialog-backdrop-close"
    type="button"
    (click)="handleSelectAnotherCity()"
  >
    <span class="sr-only">Fechar</span>
  </button>

  <div class="dialog">
    <div class="dialog-header">
      <h5 class="dialog-header-title">Bateria indisponível</h5>

      <button class="close" type="button" (click)="handleSelectAnotherCity()">
        <span class="sr-only">Fechar</span>

        <!-- prettier-ignore -->
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16"><path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/></svg>
      </button>
    </div>

    <div class="dialog-body">
      <div class="dialog-body-content">
        <h4 class="dialog-body-title">Bateria temporariamente indisponível</h4>

        <p>
          No momento estamos, em
          <span class="font-semibold">{{ selectedCity.city }}</span
          >, sem a Bateria Moura
          <span class="font-semibold">{{ selectedBattery.model }}</span
          >, que é a <span class="font-semibold">bateria indicada</span> para o
          seu <span class="font-semibold">{{ selectedVehicle.car_model }}</span
          >.
        </p>

        <p>
          Você pode selecionar uma outra cidade ou buscar pela revenda Moura
          mais próxima.
        </p>
      </div>
    </div>

    <div class="dialog-footer">
      <button
        class="btn btn-md btn-ghost"
        type="button"
        (click)="handleSearchRetailer()"
      >
        Buscar revenda
      </button>

      <button
        class="btn btn-md btn-outline"
        type="button"
        (click)="handleSelectAnotherCity()"
      >
        Selecionar outra cidade
      </button>
    </div>
  </div>
</div>
