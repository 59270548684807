import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-floating',
  templateUrl: './floating.component.html',
  styleUrls: ['./floating.component.scss'],
})
export class FloatingComponent {
  @Input() menuIsOpen: boolean;
  @Input() renderCheckPrices: boolean;
  @Input() whatsAppCTAIsAbove: boolean;

  @Input() phoneNumber: string;
  @Input() whatsAppWelcome: string;

  @Output() clickedOrderNow = new EventEmitter();
  @Output() clickedWhatsApp = new EventEmitter();

  constructor() {
    this.menuIsOpen = false;
    this.renderCheckPrices = false;
    this.whatsAppCTAIsAbove = false;

    this.phoneNumber = '';
    this.whatsAppWelcome = '';
  }
}
