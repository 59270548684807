import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SearchableVehicle } from '../../_interfaces/searchable-vehicle';

@Component({
  selector: 'app-dialog-complex-exchange',
  templateUrl: './dialog-complex-exchange.component.html',
  styleUrls: ['./dialog-complex-exchange.component.scss'],
})
export class DialogComplexExchangeComponent {
  @Input() selectedVehicle: SearchableVehicle;

  @Input() show: boolean;

  @Output() close = new EventEmitter();
  @Output() searchRetailer = new EventEmitter();

  constructor() {
    this.selectedVehicle = {} as SearchableVehicle;

    this.show = false;
  }

  public handleClose(): void {
    this.close.emit();
  }

  public handleSearchRetailer(): void {
    this.searchRetailer.emit();
  }
}
