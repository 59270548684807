import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-baseboard',
  templateUrl: './baseboard.component.html',
  styleUrls: ['./baseboard.component.scss'],
})
export class BaseboardComponent {
  @Input() phoneNumber: string;

  @Output() clickedMenuItem = new EventEmitter();
  @Output() clickedPhone = new EventEmitter();
  @Output() clickedSocialItem = new EventEmitter();

  public currentYear: number;

  constructor() {
    this.currentYear = new Date().getFullYear();
  }
}
