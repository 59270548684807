<div class="dialog-backdrop" [ngClass]="{ hidden: !show }">
  <button class="dialog-backdrop-close" type="button" (click)="handleClose()">
    <span class="sr-only">Fechar</span>
  </button>

  <div class="dialog">
    <div class="dialog-header">
      <h5 class="dialog-header-title">Serviço indisponível</h5>

      <button class="close" type="button" (click)="handleClose()">
        <span class="sr-only">Fechar</span>

        <!-- prettier-ignore -->
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16"><path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/></svg>
      </button>
    </div>

    <div class="dialog-body">
      <div class="dialog-body-content">
        <h4 class="dialog-body-title">
          Nosso serviço está temporariamente indisponível
        </h4>

        <p>Deseja falar com a gente via WhatsApp?</p>
      </div>
    </div>

    <div class="dialog-footer">
      <button
        class="btn btn-md btn-ghost"
        type="button"
        (click)="handleClose()"
      >
        Tentar novamente
      </button>

      <button
        class="btn btn-md btn-outline"
        type="button"
        (click)="handleTalkViaWhatsApp()"
      >
        Falar via WhatsApp
      </button>
    </div>
  </div>
</div>
