import { Injectable } from '@angular/core';

import { SessionInfo } from '../_interfaces/session';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private readonly SESSION_KEY = 'user_sessions';

  public addSession(sessionInfo: SessionInfo) {
    const sessions: SessionInfo[] = this.getSessions();
    const session: string = sessionStorage.getItem(this.SESSION_KEY);

    if (!session) {
      this.setSessionStorage(sessionInfo);
      sessions.push(sessionInfo);
    }

    const thirtyDaysAgo = Date.now() - 30 * 24 * 60 * 60 * 1000;
    const validSessions = sessions.filter(
      (session) => session.timestamp >= thirtyDaysAgo
    );

    this.setLocalStorage(validSessions);
  }

  public getSessionsNonDirect(): SessionInfo[] {
    const sessions: SessionInfo[] = this.getSessions();
    return sessions
      .filter(({ leadsourceNonDirect }) => leadsourceNonDirect !== 'direto')
      .sort((a, b) => b.timestamp - a.timestamp);
  }

  public getSessions(): SessionInfo[] {
    return JSON.parse(localStorage.getItem(this.SESSION_KEY)) || [];
  }

  private setSessionStorage(session: SessionInfo) {
    sessionStorage.setItem(this.SESSION_KEY, JSON.stringify(session));
  }

  private setLocalStorage(sessions: SessionInfo[]) {
    localStorage.setItem(this.SESSION_KEY, JSON.stringify(sessions));
  }
}
