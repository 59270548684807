import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dialog-service-unavailable',
  templateUrl: './dialog-service-unavailable.component.html',
  styleUrls: ['./dialog-service-unavailable.component.scss'],
})
export class DialogServiceUnavailableComponent {
  @Input() show: boolean;

  @Output() close = new EventEmitter();
  @Output() talkViaWhatsApp = new EventEmitter();

  constructor() {
    this.show = false;
  }

  public handleClose(): void {
    this.close.emit();
  }

  public handleTalkViaWhatsApp(): void {
    this.talkViaWhatsApp.emit();
  }
}
