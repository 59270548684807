import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FormStateService {
  private currentStepSubject = new BehaviorSubject<number>(1);

  public currentStep$ = this.currentStepSubject.asObservable();

  private stepValidity: boolean[] = [false, false];

  setStepValidity(step: number, isValid: boolean) {
    if (step >= 1 && step <= 3) {
      this.stepValidity[step - 1] = isValid;
    }
  }

  nextStep() {
    const currentStep = this.currentStepSubject.value;

    if (currentStep < 3 && this.stepValidity[currentStep - 1]) {
      this.currentStepSubject.next(currentStep + 1);
    }
  }

  previousStep() {
    const currentStep = this.currentStepSubject.value;

    if (currentStep > 1) {
      this.currentStepSubject.next(currentStep - 1);
    }
  }

  goToStep(step: number) {
    const currentStep = this.currentStepSubject.value;

    if (step > currentStep) {
      if (this.stepValidity[currentStep - 1]) {
        this.currentStepSubject.next(step);
      }
    } else {
      this.currentStepSubject.next(step);
    }
  }

  resetStepValidity() {
    this.stepValidity = [false, false];

    this.goToStep(1);
  }

  public setTheFirstTwoStepsAsValid(): void {
    this.setStepValidity(1, true);

    this.setStepValidity(2, true);
  }
}
