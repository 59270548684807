import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'sobre',
    loadChildren: () => import('./about/about.module').then((m) => m.AboutModule),
  },
  {
    path: 'contato',
    loadChildren: () => import('./contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'entrega/:id',
    loadChildren: () => import('./delivery/delivery.module').then((m) => m.DeliveryModule),
  },
  {
    path: 'politica-de-marketing-digital',
    loadChildren: () => import('./digital-marketing-policy/digital-marketing-policy.module').then((m) => m.DigitalMarketingPolicyModule),
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'politica-de-privacidade-plataforma-de-logistica',
    loadChildren: () => import('./logistic-platform-privacy/logistic-platform-privacy.module').then((m) => m.LogisticPlatformPrivacyModule),
  },
  {
    path: 'nao-encontrada',
    loadChildren: () => import('./not-found/not-found.module').then((m) => m.NotFoundModule),
  },
  {
    path: 'politica-de-privacidade',
    loadChildren: () => import('./privacy/privacy.module').then((m) => m.PrivacyModule),
  },
  {
    path: 'passos',
    loadChildren: () => import('./steps/steps.module').then((m) => m.StepsModule),
  },
  {
    path: 'termos',
    loadChildren: () => import('./terms/terms.module').then((m) => m.TermsModule),
  },
  {
    path: 'whats',
    loadChildren: () => import('./whats/whats.module').then((m) => m.WhatsModule),
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'nao-encontrada',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
      errorHandler: (e) => {
        console.error(e);

        throw e;
      },
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
