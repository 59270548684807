import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

import { Battery } from '../_interfaces/battery';
import { CityDetails } from '../_interfaces/city-details';
import { GoogleMerchantsProduct } from '../_interfaces/google-merchants-product';
import { Order } from '../_interfaces/order';
import { SearchableVehicle } from '../_interfaces/searchable-vehicle';
import { Testimonial } from '../_interfaces/testimonial';
import { TopCity } from '../_interfaces/top-city';
import { TopVehicle } from '../_interfaces/top-vehicle';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  /* ------------------------------------------------------------------------ */
  /* Private ---------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */

  /* Arrays ----------------------------------------------------------------- */
  private testimonialsBS = new BehaviorSubject<Testimonial[]>([] as Testimonial[]);
  private topCitiesBS = new BehaviorSubject<TopCity[]>([] as TopCity[]);
  private topVehiclesBS = new BehaviorSubject<TopVehicle[]>([] as TopVehicle[]);

  /* Objects ---------------------------------------------------------------- */
  private googleMerchantsProductBS = new BehaviorSubject<GoogleMerchantsProduct>({} as GoogleMerchantsProduct);
  private selectedBatteryBS = new BehaviorSubject<Battery>({} as Battery);
  private selectedCityBS = new BehaviorSubject<CityDetails>({} as CityDetails);
  private selectedOrderBS = new BehaviorSubject<Order>({} as Order);
  private selectedVehicleBS = new BehaviorSubject<SearchableVehicle>({} as SearchableVehicle);

  /* Booleans --------------------------------------------------------------- */
  private hasValidCouponAppliedBS = new BehaviorSubject<boolean>(false);
  private hasValidCouponBS = new BehaviorSubject<boolean>(false);
  private selectedCityIsOpenBS = new BehaviorSubject<boolean>(false);
  private showPhoneNumberBS = new BehaviorSubject<boolean>(true);
  private showWhatsAppButtonBS = new BehaviorSubject<boolean>(true);
  private userFromGoogleShoppingBS = new BehaviorSubject<boolean>(false);
  private userFromHeavyVehicleBS = new BehaviorSubject<boolean>(false);
  private userFromPartnershipBS = new BehaviorSubject<boolean>(false);
  private wantsToRescheduleBS = new BehaviorSubject<boolean>(false);

  /* Strings ---------------------------------------------------------------- */
  private leadSourceBS = new BehaviorSubject<string>('organico');
  private leadSourceDetailsBS = new BehaviorSubject<string>('organico_formulario');
  private pageTitleBS = new BehaviorSubject<string>('Moura Fácil');
  private phoneNumberBS = new BehaviorSubject<string>('4020.9721');
  private selectedManufacturerBS = new BehaviorSubject<string>('');
  private userOriginBS = new BehaviorSubject<string>('Moura Fácil');
  private utmBlogWidgetPostSlugBS = new BehaviorSubject<string>('');
  private utmCampaignBS = new BehaviorSubject<string>('');
  private utmMediumBS = new BehaviorSubject<string>('');
  private utmSourceBS = new BehaviorSubject<string>('');
  private utmTesteABBS = new BehaviorSubject<string>('');
  private utmTipoSearchBS = new BehaviorSubject<string>('');
  private whatsAppWelcomeBS = new BehaviorSubject<string>(encodeURI('Olá, preciso de uma bateria para o meu carro'));

  /* ------------------------------------------------------------------------ */
  /* Public ----------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */

  /* Arrays ----------------------------------------------------------------- */
  public testimonials$ = this.testimonialsBS.asObservable();
  public topCities$ = this.topCitiesBS.asObservable();
  public topVehicles$ = this.topVehiclesBS.asObservable();

  /* Objects ---------------------------------------------------------------- */
  public googleMerchantsProduct$ = this.googleMerchantsProductBS.asObservable();
  public selectedBattery$ = this.selectedBatteryBS.asObservable();
  public selectedCity$ = this.selectedCityBS.asObservable();
  public selectedOrder$ = this.selectedOrderBS.asObservable();
  public selectedVehicle$ = this.selectedVehicleBS.asObservable();

  /* Booleans --------------------------------------------------------------- */
  public hasValidCoupon$ = this.hasValidCouponBS.asObservable();
  public hasValidCouponApplied$ = this.hasValidCouponAppliedBS.asObservable();
  public selectedCityIsOpen$ = this.selectedCityIsOpenBS.asObservable();
  public showPhoneNumber$ = this.showPhoneNumberBS.asObservable();
  public showWhatsAppButton$ = this.showWhatsAppButtonBS.asObservable();
  public userFromGoogleShopping$ = this.userFromGoogleShoppingBS.asObservable();
  public userFromHeavyVehicle$ = this.userFromHeavyVehicleBS.asObservable();
  public userFromPartnership$ = this.userFromPartnershipBS.asObservable();
  public wantsToReschedule$ = this.wantsToRescheduleBS.asObservable();

  /* Strings ---------------------------------------------------------------- */
  public leadSource$ = this.leadSourceBS.asObservable();
  public leadSourceDetails$ = this.leadSourceDetailsBS.asObservable();
  public pageTitle$ = this.pageTitleBS.asObservable();
  public phoneNumber$ = this.phoneNumberBS.asObservable();
  public selectedManufacturer$ = this.selectedManufacturerBS.asObservable();
  public userOrigin$ = this.userOriginBS.asObservable();
  public utmBlogWidgetPostSlug$ = this.utmBlogWidgetPostSlugBS.asObservable();
  public utmCampaign$ = this.utmCampaignBS.asObservable();
  public utmMedium$ = this.utmMediumBS.asObservable();
  public utmSource$ = this.utmSourceBS.asObservable();
  public utmTesteAB$ = this.utmTesteABBS.asObservable();
  public utmTipoSearch$ = this.utmTipoSearchBS.asObservable();
  public whatsAppWelcome$ = this.whatsAppWelcomeBS.asObservable();

  /* ------------------------------------------------------------------------ */
  /* Methods ---------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */
  public updateTestimonials(value: Testimonial[]): void {
    this.testimonialsBS.next(value);
  }

  public updateTopCities(value: TopCity[]): void {
    this.topCitiesBS.next(value);
  }

  public updateTopVehicles(value: TopVehicle[]): void {
    this.topVehiclesBS.next(value);
  }

  public updateGoogleMerchantsProduct(value: GoogleMerchantsProduct): void {
    this.googleMerchantsProductBS.next(value);
  }

  public updateSelectedBattery(value: Battery): void {
    this.selectedBatteryBS.next(value);
  }

  public updateSelectedCity(value: CityDetails): void {
    this.selectedCityBS.next(value);
  }

  public updateSelectedOrder(value: Order): void {
    this.selectedOrderBS.next(value);
  }

  public updateSelectedVehicle(value: SearchableVehicle): void {
    this.selectedVehicleBS.next(value);
  }

  public updateHasValidCoupon(value: boolean): void {
    this.hasValidCouponBS.next(value);
  }

  public updateHasValidCouponApplied(value: boolean): void {
    this.hasValidCouponAppliedBS.next(value);
  }

  public updateSelectedCityIsOpen(value: boolean): void {
    this.selectedCityIsOpenBS.next(value);
  }

  public updateShowPhoneNumber(value: boolean): void {
    this.showPhoneNumberBS.next(value);
  }

  public updateShowWhatsAppButton(value: boolean): void {
    this.showWhatsAppButtonBS.next(value);
  }

  public updateUserFromGoogleShopping(value: boolean): void {
    this.userFromGoogleShoppingBS.next(value);
  }

  public updateUserFromHeavyVehicle(value: boolean) {
    this.userFromHeavyVehicleBS.next(value);
  }

  public updateUserFromPartnership(value: boolean): void {
    this.userFromPartnershipBS.next(value);
  }

  public updateWantsToReschedule(value: boolean): void {
    this.wantsToRescheduleBS.next(value);
  }

  public updateLeadSource(value: string): void {
    this.leadSourceBS.next(value);
  }

  public updateLeadSourceDetails(value: string): void {
    this.leadSourceDetailsBS.next(value);
  }

  public updatePageTitle(value: string): void {
    this.pageTitleBS.next(value);
  }

  public updatePhoneNumber(value: string): void {
    this.phoneNumberBS.next(value);
  }

  public updateSelectedManufacturer(value: string): void {
    this.selectedManufacturerBS.next(value);
  }

  public updateUserOrigin(value: string): void {
    this.userOriginBS.next(value);
  }

  public updateUTMBlogWidgetPostSlug(value: string): void {
    this.utmBlogWidgetPostSlugBS.next(value);
  }

  public updateUTMCampaign(value: string): void {
    this.utmCampaignBS.next(value);
  }

  public updateUTMMedium(value: string): void {
    this.utmMediumBS.next(value);
  }

  public updateUTMSource(value: string): void {
    this.utmSourceBS.next(value);
  }

  public updateUTMTesteAB(value: string): void {
    this.utmTesteABBS.next(value);
  }

  public updateUTMTipoSearch(value: string): void {
    this.utmTipoSearchBS.next(value);
  }

  public updateWhatsAppWelcome(value: string): void {
    this.whatsAppWelcomeBS.next(value);
  }
}
