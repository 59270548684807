export const environment = {
  apis: {
    mouraBlog: '$MOURA_BLOG_URI',
    mouraDigitalServices: '$MOURA_DIGITAL_SERVICES_URI',
    mouraFacil: '$MOURA_FACIL_URI',
    mouraRealTime: '$MOURA_REALTIME_URI',
    mouraSearch: '$MOURA_SEARCH_URI',
    mouraServices: '$MOURA_SERVICES_URI',
  },
  keys: {
    googleMaps: '$GOOGLE_MAPS_KEY',
    mouraSearch: '$MOURA_SEARCH_KEY',
  },
  mouseFlow: {
    projectId: '$MOUSEFLOW_PROJECT_ID',
  },
  production: '$PRODUCTION',
  sentry: {
    dsn: '$SENTRY_DSN',
  },
  slugCommit: '$HEROKU_SLUG_COMMIT',
  tokens: {
    mouraRealTime: '$MOURA_REALTIME_TOKEN',
  },
  version: '$HEROKU_RELEASE_VERSION',
};
